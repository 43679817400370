import React from 'react';
import { colourHelper } from './../util';

const SanityBlurb = ({ data: { colour, heading } }) => {
  return (
    <div className={`px-5 md:px-6 py-40 text-center ${colourHelper[colour]}`}>
      <p className="text-lg md:text-xl max-w-2xl mx-auto">{heading}</p>
    </div>
  );
};

export default SanityBlurb;
