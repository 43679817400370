import React from 'react';

const SanityIconList = ({ data }) => {
  return (
    <div className="px-5 md:px-6 py-12 md:py-32 font-sans bg-gray-500 text-slate">
      <ul className="md:flex flex-wrap">
        {data.array.map((el, index) => (
          <li
            key={index}
            className="md:px-12 md:mb-12 md:w-1/2 lg:w-1/3 mb-4 md:mb-0"
          >
            <div className="relative pl-4">
              <div className="top-0 left-0 absolute pt-3">
                <div className="rounded-full w-1 h-1 bg-slate"></div>
              </div>
              <p className="antialiased leading-loose md:text-base">
                {el.text}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SanityIconList;
