import React from 'react';
import { colourHelper } from './../util';

const SanityHeadline = ({ data: { heading, colour } }) => {
  return (
    <div className={`${colourHelper[colour]}`}>
      <p className="px-5 md:px-6 pt-12 lg:pt-32 text-xl md:text-2xl">
        &mdash; {heading}
      </p>
    </div>
  );
};

export default SanityHeadline;
