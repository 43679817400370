import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import SanityBlurb from '../components/homepage/SanityBlurb';
import SanityFeaturedProject from '../components/homepage/SanityFeaturedProject';
import SanityHeadline from '../components/homepage/SanityHeadline';
import SanityIconList from '../components/homepage/SanityIconList';
import SanityImageText from '../components/homepage/SanityImageText';
import SanityTextImage from '../components/homepage/SanityTextImage';
import Footer from '../components/footer';
import Logo from '../components/logo';
import SEO from '../components/seo';

const components = {
  blurb: SanityBlurb,
  'featured-project': SanityFeaturedProject,
  headline: SanityHeadline,
  'icon-list': SanityIconList,
  'image-text': SanityImageText,
  'text-image': SanityTextImage,
};

function IndexPage({
  data: {
    sanityHomepage: { tabs },
  },
}) {
  // console.log('tabs');
  // console.log(tabs.content);
  const image = getImage(tabs.heroImage.asset);
  return (
    <main className="font-serif text-gray-800 bg-slate text-sm">
      <header className="absolute top-0 left-0 h-0 z-10">
        <div className="pl-5 md:pl-6 pt-8 text-white">
          <Logo />
        </div>
      </header>
      <SEO title={tabs.title} />
      <div className="h-screen">
        {tabs.heroImage && (
          <GatsbyImage
            className="h-full w-full"
            objectFit="cover"
            image={image}
            loading="eager"
            alt="alt"
          />
        )}
      </div>
      {tabs.content.map((el) => {
        // console.log(el);
        return React.createElement(components[el._type], {
          key: el._key,
          data: el,
        });
      })}
      <Footer />
    </main>
  );
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    sanityHomepage(id: { eq: "-e07ca5b6-bcc6-5d90-8489-39702cd11394" }) {
      id
      tabs {
        description
        title
        heroImage {
          asset {
            gatsbyImageData(width: 1440)
          }
        }
        content {
          ... on SanityBlurb {
            _key
            _type
            colour
            heading
          }
          ... on SanityFeaturedProject {
            _key
            _type
            project {
              name
              slug {
                current
              }
              feature {
                asset {
                  gatsbyImageData(width: 1440)
                }
              }
            }
          }
          ... on SanityHeadline {
            _key
            _type
            heading
            colour
          }
          ... on SanityIconList {
            _key
            _type
            array {
              text
            }
          }
          ... on SanityImageText {
            _key
            _type
            image {
              asset {
                gatsbyImageData(width: 1440)
              }
            }
            _rawText(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityTextImage {
            _key
            _type
            image {
              asset {
                gatsbyImageData(width: 1440)
              }
            }
            _rawText(resolveReferences: { maxDepth: 10 })
          }
        }
      }
    }
  }
`;
