import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const SanityFeaturedProject = ({ data }) => {
  return (
    <section className="bg-white">
      <div className="px-5 md:px-6 md:flex py-12 lg:py-40">
        <div className="md:px-6 md:w-1/2">
          <Link to={data.project.slug.current} className="group">
            <p className="mb-8 md:mb-0 text-xl transition-opacity duration-500 group-hover:opacity-75">
              View our latest project{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 30"
                x="0px"
                y="0px"
                className="hidden md:inline-block w-5 ml-1"
              >
                <g>
                  <g>
                    <polygon points="20.02 0 18.66 1.33 28.37 11.05 0 11.05 0 12.95 28.37 12.95 18.66 22.68 20.02 24 32 12 20.02 0" />
                  </g>
                </g>
              </svg>
            </p>
          </Link>
        </div>
        <div className="md:w-5/12">
          <Link to={data.project.slug.current} className="group">
            <GatsbyImage
              alt=""
              className="group-hover:opacity-75 transition-opacity duration-500"
              loading="eager"
              image={getImage(data.project.feature.asset)}
            />
            <p className="p-2 font-sans antialiased transition-opacity duration-500 group-hover:opacity-75">
              {data.project.name}
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SanityFeaturedProject;
